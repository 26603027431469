//Imports
import React from "react";
import { graphql } from 'gatsby';

import Layout from '../components/layout/Layout';
import styles from './404.module.scss';

//Check if it is a redirect
const browser = typeof window !== "undefined" && window;

//Component
const NotFoundPage = ({data}) => {
	/* -- Variables -- */
	const seo = data.allWordpressPage.edges[0].node.yoast_meta;
	const seoRobots = data.allWordpressPage.edges[0].node.acf.search_engine;
	const seoImage = data.allWordpressPage.edges[0].node.acf.social_image;

	const headScripts = data.allWordpressAcfOptions.edges[0].node.options.third_party_head_scripts;
	const bodyScripts = data.allWordpressAcfOptions.edges[0].node.options.third_party_body_scripts;

	return (
		browser && (
			<Layout seo={seo} seoRobots={seoRobots} seoImage={seoImage} headScripts={headScripts} bodyScripts={bodyScripts}>
				<div className={`${styles.notFound} container`}>
					<div className="row">
						<div className="col-12">
							<div className={styles.content}>
								<h1><span>(404)</span> Page Not Found</h1>
								<p>Things change, including websites. This page may have been moved, altered, or even possibly obliterated causing you to end up here – most likely where you were not trying to find yourself.</p>
								<p>Double check your URL to see if there are any typos. If it looks like you’re where you’re supposed to be, <a href="https://uberflip.com/contact" target="_blank" rel="noreferrer noopener">contact us</a> and we’ll get you the info you’re looking for.</p>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	);
}

export default NotFoundPage;

export const query = graphql`
	query {
		allWordpressAcfOptions {
			edges {
				node {
					options {
						third_party_head_scripts {
								script
								src
								attributes
						}
						third_party_body_scripts {
								script
								src
								attributes
						}
					}
				}
			}
		}
		allWordpressPage(filter: {slug: {ne: "dummy", eq: "home"}}) {
			edges {
				node {
					yoast_meta {
						yoast_wpseo_title
						yoast_wpseo_metadesc
					}
					acf {
						search_engine
						social_image {
							source_url
						}
					}
				}
			}
		}
	}
`;